/**
 * Created by jimmy on 2019/9/29.
 */
import React, { useState, useEffect } from 'react';
import './index.less';
import {WarningOutlined} from "@ant-design/icons";
import {Button, Form, Input, Cascader, message, Modal, Select, DatePicker} from "antd";
import * as Request from "../../common/request";
import * as CONST from '../../common/const';
import moment from "moment";
import UploadPictureList from '../../components/uploadPictureList';

const CompModel = props => {
	let { domId, style, record = {},workerList, baseCategoryList, sevenCategory } = props;
	let sevenCode = null, isfast = null;
	let categoryValue = record.category || record.categoryBu;
	const categoryFirstWord = record.category ? 'S' : 'B';
	const [tempFormValues, setTempFormValues] = useState(null);

	// console.log("WorkerList",workerList);
	const [ workerDepartmentList, setWorkerDepartmentList ] = useState([]);
	// const [ workerLocalList, setWorkerLocalList ] = useState([]);
	const getWorkerList = async () => {
		if(!workerList || workerList.length === 0){
			const result = await Request.get({
				url: '/admin/user/worker/list'
			});
			if(!result) return;

			if(result.data.list && result.data.list.length > 0) {
				workerList = result.data.list;
			}
		}
		// console.log("getWorkerList run",workerList);
		if(workerList && workerList.length > 0) {

			const workerDepartmentListMap = {};
			const mold = ['---', '网格','热线','热线+网格']
			workerList.map(item => {
				workerDepartmentListMap[item.department] = workerDepartmentListMap[item.department] || [];
				workerDepartmentListMap[item.department].push({ ...item, label: item.nickname + '(' + mold[item.umold] +')', value: item.id });
			});
			const workerAndDepartmentList = Object.keys(workerDepartmentListMap).map(key => ({ label: key, value: key, children: workerDepartmentListMap[key] }));
			setWorkerDepartmentList(workerAndDepartmentList);
			console.log("workerList",workerAndDepartmentList);
		}
	};
	useEffect(() => {
		getWorkerList();
	}, [ 123 ]);

	// const [ sevenCategory, setSevenCategoryList ] = useState([]);
	// const getSevenCategoryList = async () => {
	// 	// console.log("getCategoryList")
	// 	// const result = await Request.get({
	// 	// 	url: '/admin/problem/category/list',
	// 	// 	data: { type: 'seven' }
	// 	// });
	// 	// if(!result) return;
	// 	// if(result.data.list && result.data.list.length > 0) {
	// 	// 	setSevenCategoryList(result.data.list);
	// 	// }
	// 	if(CONST.sevenCategoryList && CONST.sevenCategoryList.length > 0) {
	// 		setSevenCategoryList(CONST.sevenCategoryList);
	// 	}
	// };
	// useEffect(() => {
	// 	getSevenCategoryList();
	// }, [ 123 ]);

	const [ categoryList, setCategoryList ] = useState([]);
	const getCategoryList = async () => {
		// console.log("getCategoryList")
		// const result = await Request.get({
		// 	url: '/admin/problem/category/list'
		// });
		// if(!result) return;
		if(baseCategoryList && baseCategoryList.length > 0) {
			const list = baseCategoryList.map((item => ({
				...item,
				value:item.code,
				label: item.label
			})));
			const categoryList = [];
			const listParentIdMap = {};
			list.map(lst => {
				if(categoryFirstWord && lst.code.startsWith(categoryFirstWord)) {
					if(lst.parentId == '0') {
						categoryList.push(lst);
					}else{
						const key = lst.parentId;
						listParentIdMap[key] = listParentIdMap[key] || [];
						listParentIdMap[key].push(lst);
					}
				}
			});
			categoryList.map(item => {
				item.children = listParentIdMap[item.code];

				item.children && item.children.map(citem => {
					citem.children = listParentIdMap[citem.code];
					citem.children && citem.children.map(scitem =>{
						scitem.children = listParentIdMap[scitem.code];
					})
				})
			});
			setCategoryList(categoryList);
		}
	};
	useEffect(() => {
		getCategoryList();
	}, [ 123 ]);

	const [ modalFlag, setModalFlag] = useState(false); 
	const [ metchinFlag, setMetchinFlag] = useState(false); 
	const [ modalConfig, setModalConfig ] = useState({});
	const [ processor, setProcessor ] = useState('');
	const [ processorExpired, setProcessorExpired ] = useState('');
	const [ delayExpired, setDelayExpired ] = useState('');
	const [ result, setResult ] = useState('');
	const [ fileLists, setFileList] = useState([]);
	const [ agree, setAgree ] = useState(0);

	const [ editContent, setEditContent ] = useState(record.content);
	const [ editAddress, setEditAddress ] = useState(record.address);
	const [ category, setCategory ] = useState(record.category);

	const doUpdate = async (data, callback) => {
		const result = await Request.post({
			url: '/admin/problem/update',
			data
		});
		if(!result) return;

		if(callback && typeof callback == 'function') {
			callback();
		}
		if(props.callback && typeof props.callback == 'function'){
			props.callback();
		}
	};
	const doMatching = async (data, callback) => {
		const result = await Request.post({
			url: '/admin/problem/ismatching',
			data
		});
		if (!result) return;
		console.log('object===',result);

		if (callback && typeof callback == 'function') {
			callback(result.data);
		}
	}
	const doProcess = async (data, callback) => {
		
		const result = await Request.post({
			url: '/admin/problem/process/do',
			data
		});
		if(!result) return;

		if(callback && typeof callback == 'function') {
			callback();
		}
		if(props.callback && typeof props.callback == 'function'){
			props.callback();
		}
	};
	const cancelMetching = () =>{
		setMetchinFlag(false)
	}
	const cancelModal = () => {
		setModalConfig({});
		setModalFlag(false);
	};

	const _style = {...style};
	const buttonDom = {
		'11-bianji': <Button size="small" style={_style} key="shouli" onClick={() => {
			setModalFlag(true);
			setModalConfig({
				modalTitle: '案件编辑？',
				body: <>
					{/*<Form.Item label={null}>
						<Select placeholder="案件类型" onChange={value => {

						}}>

						</Select>
					</Form.Item>*/}
					<Form.Item label="案件描述">
						<Input placeholder="案件描述" defaultValue={record.content} onChange={e => {
							setEditContent(e.target.value);
						}} />
					</Form.Item>
					<Form.Item label="发生地址">
						<Input placeholder="案件发生地址" defaultValue={record.address} onChange={e => {
							setEditAddress(e.target.value);
						}} />
					</Form.Item>
					<Form.Item label="问题类型">
						<Cascader options={categoryList} defaultValue={categoryValue} onChange={(value) => {
							// setCategory(value);
							console.log("categoryValue",categoryValue)
							console.log("value",value)
							categoryValue = value;
						}} />
					</Form.Item>
				</>,
				action: (obj = {}) => {
					const updateObj = {
						id: record.id,
						content: obj.editContent,
						address: obj.editAddress
					};
					if(categoryFirstWord == 'B') {
						updateObj.categoryBu = categoryValue;
					}else if(categoryFirstWord == 'S') {
						updateObj.category = categoryValue;
					}

					doUpdate(updateObj, () => {
						message.success('编辑成功');
						cancelModal();
					});
				}
			});
		}}>编辑</Button>,
		'11-shouli': <Button size="small" type="primary" style={_style} key="shouli" onClick={() => {
			setModalFlag(true);
			setModalConfig({
				title: '确认受理？',
				action: () => {
					doProcess({
						id: record.id,
						status: 21
					}, () => {
						message.success('受理成功，请到「案件立案」中查看操作');
						cancelModal();
					});
				}
			});
		}}>受理</Button>,
		'11-shoulilian': <Button size="small" type="primary" style={_style} key="shoulilian" onClick={() => {
			setModalFlag(true);
			setModalConfig({
				title: '确认受理并立案？',
				body: <>
					{
						sevenCategory && sevenCategory.length > 0 && <>
							<div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, marginBottom: 6 }}>
								<span>如若该案件属于"七大类"案件，请选择对应分类；不选择分类视为非七大类案件</span>
								<div style={{ color: 'rgb(228, 83, 87, 0.85)' }}><WarningOutlined />该操作涉及到给市民积分，请谨慎操作</div>
							</div>
							<Select placeholder='请选择"七大类"案件说明' style={{ width: '100%' }} onChange={value => {
								sevenCode = value;
							}}>
								{
									sevenCategory && sevenCategory.length > 0 && sevenCategory.map((item, idx) => {
										return <Select.Option value={item.code} key={idx}>{item.label}</Select.Option>
									})
								}
							</Select>
						</>
					}
				</>,
				action: () => {
					doProcess({
						id: record.id,
						sevenCode,
						status: 31
					}, () => {
						message.success('受理并立案成功，请到「案件派遣」中查看操作');
						cancelModal();
					});
				}
			});
		}}>受理并立案</Button>,
		'11-zuofie': <Button size="small" type="primary" style={_style} key="zuofie" onClick={() => {
			setModalFlag(true);
			setModalConfig({
				title: '确认作废？',
				body: <>
					<Form.Item label={null} extra={<span style={{ color: 'red' }}>*作废理由不能为空</span>}>
						<Input.TextArea placeholder="请输入作废理由" rows={4} onChange={e => {
							setResult(e.target.value);
						}} />
					</Form.Item>
				</>,
				action: (obj = {}) => {
					if(!obj.result) {
						message.error('作废理由不能为空，操作失败');
						return;
					}
					doProcess({
						id: record.id,
						status: 12,
						result: obj.result
					}, () => {
						message.success('案件作废成功');
						cancelModal();
					});
				}
			});
		}}>作废</Button>,

		'21-lian': <Button size="small" type="primary" style={_style} key="lian" onClick={() => {
			setModalFlag(true);
			setModalConfig({
				title: '确认立案？',
				body: <>
					{
						sevenCategory && sevenCategory.length > 0 && <>
							<div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, marginBottom: 6 }}>
								<span>如若该案件属于"七大类"案件，请选择对应分类；不选择分类视为非七大类案件</span>
								<div style={{ color: 'rgb(228, 83, 87, 0.85)' }}><WarningOutlined />该操作涉及到给市民积分，请谨慎操作</div>
							</div>
							<Select placeholder='请选择"七大类"案件说明' style={{ width: '100%' }} onChange={value => {
								sevenCode = value;
							}}>
								{
									sevenCategory && sevenCategory.length > 0 && sevenCategory.map((item, idx) => {
										return <Select.Option value={item.code} key={idx}>{item.label}</Select.Option>
									})
								}
							</Select>
						</>
					}
				</>,
				action: () => {
					doProcess({
						id: record.id,
						sevenCode,
						status: 31
					}, () => {
						message.success('立案成功，请到「案件派遣」中查看操作');
						cancelModal();
					});
				}
			});
		}}>立案</Button>,
		'21-tuihui': <Button size="small" type="primary" style={_style} key="tuihui" onClick={() => {
			setModalFlag(true);
			setModalConfig({
				title: '确认退回？',
				action: () => {
					doProcess({
						id: record.id,
						status: 11
					}, () => {
						message.success('退回成功，请到「待受理」中查看操作');
						cancelModal();
					});
				}
			});
		}}>退回</Button>,

		'31-paiqian': <Button size="small" type="primary" style={_style} key="paiqian" onClick={() => {
			//先判断是否疑似重复订单，是-直接归档   不是--再处理
			doMatching({
				id: record.id,
			}, (data) => {
				console.log('data==',data);
				if(data > 0){
					setMetchinFlag(true);
					setModalConfig({
						title: '系统判定：此订单为高度疑似重复订单，(同一个人，同一个地点，同一个问题),请确认是否继续？',
						body: <>
							<Form.Item label={null}>
								<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
									<Button onClick={() => { 
										doProcess({
											id: record.id,
											status: 21,
											result: '系统重复订单'
										}, () => {
											message.success('归档成功');
											cancelModal();
										});
									}}>直接归档</Button>
									<Button type="primary" style={{ marginLeft: 16 }} onClick={()=>{
										setModalFlag(true);
										setModalConfig({
											title: '确认派遣？',
											body: <>
												<Form.Item label={null} extra={<span style={{ color: 'red' }}>*派遣人员不能为空</span>}>
													<Cascader
														showSearch
														options={workerDepartmentList}
														placeholder="请选择派遣人员"
														onChange={value => {
															// console.log('value', value);
															if(value && value[1]){
																setProcessor(value[1]);
															}
														}}
													/>
												</Form.Item>
												<Form.Item label={null} extra={<span style={{ color: 'red', width: '100%' }}>是否紧急案件</span>}>
													<Select placeholder='请选择' style={{ width: '100%' }} onChange={value => {
														isfast = value;
													}}>
														{
															[{ "label": "是", code: 1 }, { "label": "不是", code: 0 }].map((item, idx) => {
																return <Select.Option value={item.code} key={idx}>{item.label}</Select.Option>
															})
														}
													</Select>
												</Form.Item>
												<Form.Item label={null} extra={<span style={{ color: 'red', width: '100%' }}>*必填 案件处理截止日期</span>}>
													<DatePicker placeholder="请选择案件处理截止时间" style={{ width: '100%' }} onChange={(date, dateString) => {
														setProcessorExpired(dateString);
													}} />
												</Form.Item>
												<Form.Item label={null}>
													<Input.TextArea placeholder="请输入派遣理由" rows={4} onChange={e => {
														setResult(e.target.value);
													}} />
												</Form.Item>
												<Form.Item label={null}>
													{
														sevenCategory && sevenCategory.length > 0 && <>
															<div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, marginBottom: 6 }}>
																<span>如若该案件属于"七大类"案件，请选择对应分类；不选择分类视为非七大类案件</span>
																<div style={{ color: 'rgb(228, 83, 87, 0.85)' }}><WarningOutlined />该操作涉及到给市民积分，请谨慎操作</div>
															</div>
															<Select placeholder='请选择"七大类"案件说明' style={{ width: '100%' }} onChange={value => {
																sevenCode = value;
															}}>
																{
																	sevenCategory && sevenCategory.length > 0 && sevenCategory.map((item, idx) => {
																		return <Select.Option value={item.code} key={idx}>{item.label}</Select.Option>
																	})
																}
															</Select>
														</>
													}
												</Form.Item>
											</>,
											action: (obj = {}) => {
												if(!obj.processor) {
													message.error('派遣人员不能为空，操作失败');
													return;
												}
												if(!obj.processorExpired) {
													message.error('案件处理截止时间不能为空');
													return;
												}
												doProcess({
													id: record.id,
													status: record.type == 'hotevent' ? 40 : 33,
													isfast,
													sevenCode,
													processor: obj.processor,
													processorExpired: obj.processorExpired,
													result: obj.result
												}, () => {
													message.success('派遣成功');
													cancelModal();
												});
											}
										});
									}}>继续派遣</Button>
								</div>
							</Form.Item>
						</>,
					});
				}else{
					setModalFlag(true);
					setModalConfig({
						title: '确认派遣？',
						body: <>
							<Form.Item label={null} extra={<span style={{ color: 'red' }}>*派遣人员不能为空</span>}>
								<Cascader
									showSearch
									options={workerDepartmentList}
									placeholder="请选择派遣人员"
									onChange={value => {
										// console.log('value', value);
										if (value && value[1]) {
											setProcessor(value[1]);
										}
									}}
								/>
							</Form.Item>
							<Form.Item label={null} extra={<span style={{ color: 'red', width: '100%' }}>是否紧急案件</span>}>
								<Select placeholder='请选择' style={{ width: '100%' }} onChange={value => {
									isfast = value;
								}}>
									{
										[{ "label": "是", code: 1 }, { "label": "不是", code: 0 }].map((item, idx) => {
											return <Select.Option value={item.code} key={idx}>{item.label}</Select.Option>
										})
									}
								</Select>
							</Form.Item>
							<Form.Item label={null} extra={<span style={{ color: 'red', width: '100%' }}>*必填 案件处理截止日期</span>}>
								<DatePicker placeholder="请选择案件处理截止时间" style={{ width: '100%' }} onChange={(date, dateString) => {
									setProcessorExpired(dateString);
								}} />
							</Form.Item>
							<Form.Item label={null}>
								<Input.TextArea placeholder="请输入派遣理由" rows={4} onChange={e => {
									setResult(e.target.value);
								}} />
							</Form.Item>
							<Form.Item label={null}>
								{
									sevenCategory && sevenCategory.length > 0 && <>
										<div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, marginBottom: 6 }}>
											<span>如若该案件属于"七大类"案件，请选择对应分类；不选择分类视为非七大类案件</span>
											<div style={{ color: 'rgb(228, 83, 87, 0.85)' }}><WarningOutlined />该操作涉及到给市民积分，请谨慎操作</div>
										</div>
										<Select placeholder='请选择"七大类"案件说明' style={{ width: '100%' }} onChange={value => {
											sevenCode = value;
										}}>
											{
												sevenCategory && sevenCategory.length > 0 && sevenCategory.map((item, idx) => {
													return <Select.Option value={item.code} key={idx}>{item.label}</Select.Option>
												})
											}
										</Select>
									</>
								}
							</Form.Item>
						</>,
						action: (obj = {}) => {
							if (!obj.processor) {
								message.error('派遣人员不能为空，操作失败');
								return;
							}
							if (!obj.processorExpired) {
								message.error('案件处理截止时间不能为空');
								return;
							}
							doProcess({
								id: record.id,
								status: record.type == 'hotevent' ? 40 : 33,
								isfast,
								sevenCode,
								processor: obj.processor,
								processorExpired: obj.processorExpired,
								result: obj.result
							}, () => {
								message.success('派遣成功');
								cancelModal();
							});
						}
					});
				}
			});
		}}>派遣</Button>,
		'31-tuidan': <Button size="small" type="primary" style={_style} key="paiqian" onClick={() => {
			setModalFlag(true);
			setModalConfig({
				title: '确认归档？',
				body: <>
					<Form.Item label={null}>
						<Input.TextArea placeholder="请输入归档理由" rows={4} onChange={e => {
							setResult(e.target.value);
						}} />
					</Form.Item>
				</>,
				action: (obj = {}) => {
					doProcess({
						id: record.id,
						status: 21,
						result: obj.result
					}, () => {
						message.success('归档成功');
						cancelModal();
					});
				}
			});
		}}>归档</Button>,

		'32-paiqian': <Button size="small" type="primary" style={_style} key="paiqian" onClick={() => {
			setModalFlag(true);
			setModalConfig({
				title: '确认派遣？',
				body: <>
					<Form.Item label={null} extra={<span style={{ color: 'red' }}>*派遣人员不能为空</span>}>
						<Select placeholder="请选择派遣人员" onChange={value => {
							setProcessor(value);
						}}>
							{
								workerList && workerList.length > 0 && workerList.map((item, idx) => (
									<Select.Option key={idx} value={item.id}>{item.nickname}</Select.Option>
								))
							}
						</Select>
					</Form.Item>
					<Form.Item label={null} extra={<span style={{ color: 'red' }}>*必填</span>}>
						<DatePicker placeholder="请选择案件处理截止时间" onChange={(date, dateString) => {
							setProcessorExpired(dateString);
						}} />
					</Form.Item>
					<Form.Item label={null}>
						<Input.TextArea placeholder="请输入派遣理由" rows={4} onChange={e => {
							setResult(e.target.value);
						}} />
					</Form.Item>
				</>,
				action: (obj = {}) => {
					if(!obj.processor) {
						message.error('派遣人员不能为空，操作失败');
						return;
					}
					if(!obj.processorExpired) {
						message.error('案件处理截止时间不能为空');
						return;
					}
					doProcess({
						id: record.id,
						status: record.type == 'hotevent' ? 40 : 33,
						processor: obj.processor,
						processorExpired: obj.processorExpired,
						result: obj.result || ''
					}, () => {
						message.success('派遣成功');
						cancelModal();
					});
				}
			});
		}}>再派遣</Button>,
		'32-tuidan': <Button size="small" type="primary" style={_style} key="paiqian" onClick={() => {
			setModalFlag(true);
			setModalConfig({
				title: '确认归档？',
				body: <>
					<Form.Item label={null}>
						<Input.TextArea placeholder="请输入归档理由" rows={4} onChange={e => {
							setResult(e.target.value);
						}} />
					</Form.Item>
				</>,
				action: (obj = {}) => {
					doProcess({
						id: record.id,
						status: 21,
						result: obj.result || ''
					}, () => {
						message.success('归档成功');
						cancelModal();
					});
				}
			});
		}}>归档</Button>,

		'33-shouhuipaiqian': <Button size="small" type="primary" style={_style} key="shouhuipaiqian" onClick={() => {
			setModalFlag(true);
			setModalConfig({
				title: '确认收回派遣？',
				body: <>
					<Form.Item label={null}>
						<Input.TextArea placeholder="请输入收回派遣理由" rows={4} onChange={e => {
							setResult(e.target.value);
						}} />
					</Form.Item>
				</>,
				action: (obj = {}) => {
					doProcess({
						id: record.id,
						status: 31,
						result: obj.result || ''
					}, () => {
						message.success('收回派遣操作成功');
						cancelModal();
					});
				}
			});
		}}>收回派遣</Button>,
		'33-cuidan': <Button size="small" type="primary" style={_style} key="shouhuipaiqian" onClick={() => {
			setModalFlag(true);
			setModalConfig({
				title: '确认催单？',
				action: (obj = {}) => {
					doProcess({
						id: record.id,
						status: 34,
						result: obj.result || ''
					}, () => {
						message.success('催单操作成功');
						cancelModal();
					});
				}
			});
		}}>催单</Button>,
		'40-chuli': <Button size="small" type="primary" style={_style} key="jiean" onClick={() => {
			setModalFlag(true);
			setModalConfig({
				title: '处理结果',
				body: <>
					<Form.Item label={null}>
						<Input.TextArea placeholder="请输入处理结果" rows={4} onChange={e => {
							setResult(e.target.value);
						}} />
					</Form.Item>
					<Form.Item label={null}>
						<UploadPictureList {...{
							// pictureLength: 1,
							action: CONST.uploadPrefix + '/admin/problem/upload',
							fileList: tempFormValues && tempFormValues.cover || [],
							changeHandle: fileList => {
								console.log('===fileList===',fileList);
								setFileList(fileList);
							}
						}} />
					</Form.Item>
				</>,
				action: (obj = {}) => {
					doProcess({
						id: record.id,//事件id
						result: obj.result || '',//事件的处理结果
						images: obj.fileLists,//处理结果对应的政策性文件
						status: 41//把事件状态改为41 
					}, () => {
						message.success('已处理');
						cancelModal();
					});
				}
			});
		}}>马上处理</Button>,
		'41-jiean': <Button size="small" type="primary" style={_style} key="jiean" onClick={() => {
			setModalFlag(true);
			setModalConfig({
				title: '确认结案？',
				action: () => {
					doProcess({
						id: record.id,
						status: 42
					}, () => {
						message.success('结案成功');
						cancelModal();
					});
				}
			});
		}}>结案</Button>,
		'41-tuihuichuli': <Button size="small" type="primary" style={_style} key="tuihuichuli" onClick={() => {
		setModalFlag(true);
		setModalConfig({
			title: '确认退回？',
			body: <>
				<Form.Item label={null}>
					<Input.TextArea placeholder="请输入退回理由" rows={4} onChange={e => {
						setResult(e.target.value);
					}} />
				</Form.Item>
			</>,
			action: (obj = {}) => {
				doProcess({
					id: record.id,
					status: record.type == 'hotevent' ? 43 : 33,
					result: obj.result || ''
				}, () => {
					message.success('退回成功');
					cancelModal();
				});
			}
		});
	}}>退回处理</Button>,

		'51-tuidanshenhe': <Button size="small" type="primary" style={_style} key="zaipaiqian" onClick={() => {
			setModalFlag(true);
			setModalConfig({
				title: '确认？',
				body: <>
					<Form.Item label={null} extra={<span style={{ color: 'red' }}>*必选</span>}>
						<Select placeholder="请选择是否同意退单" onChange={value => {
							setAgree(value);
						}}>
							{
								[{ id: 1, label: '是' }, { id: 2, label: '否' }].map((item, idx) => (
									<Select.Option key={idx} value={item.id}>{item.label}</Select.Option>
								))
							}
						</Select>
					</Form.Item>
					<Form.Item label={null}>
						<Input.TextArea placeholder="请输入理由" rows={4} onChange={e => {
							setResult(e.target.value);
						}} />
					</Form.Item>
				</>,
				action: (obj = {}) => {
					if(!obj.agree) {
						message.error('请选择是否同意');
						return;
					}
					doProcess({
						id: record.id,
						status: ({ 1: 31, 2: 33 })[obj.agree],
						result: obj.result || ''
					}, () => {
						message.success('操作成功');
						cancelModal();
					});
				}
			});
		}}>退单审核</Button>,
		'52-yanqi': <Button size="small" type="primary" style={_style} key="zaipaiqian" onClick={() => {
			setModalFlag(true);
			setModalConfig({
				title: `确认案件延期${record.delayExpired}处理？`,
				body: <>
					<Form.Item label={null} extra={<span style={{ color: 'red' }}>*必选</span>}>
						<Select placeholder="请选择是否同意退单" onChange={value => {
							setAgree(value);
						}}>
							{
								[{ id: 1, label: '是' }, { id: 2, label: '否' }].map((item, idx) => (
									<Select.Option key={idx} value={item.id}>{item.label}</Select.Option>
								))
							}
						</Select>
					</Form.Item>
					<Form.Item label={null} extra={<span style={{ color: 'red' }}>*必填</span>}>
						<DatePicker placeholder="请选择延期时间" defaultValue={moment(record.delayExpired.replace(/-/gi, '/'))}  onChange={(date, dateString) => {
							setDelayExpired(dateString);
						}} />
					</Form.Item>
					<Form.Item label={null}>
						<Input.TextArea placeholder="请输入理由" rows={4} onChange={e => {
							setResult(e.target.value);
						}} />
					</Form.Item>
				</>,
				action: (obj = {}) => {
					if(!obj.agree) {
						message.error('请选择是否同意');
						return;
					}
					doProcess({
						id: record.id,
						status: 33,
						delayExpired: obj.delayExpired || record.delayExpired,
						processorExpired: obj.agree == 1 && (obj.delayExpired || record.delayExpired) || record.processorExpired,
						result: result || ''
					}, () => {
						message.success('操作成功');
						cancelModal();
					});
				}
			});
		}}>延期审核</Button>,
		'53-shensu': <Button size="small" type="primary" style={_style} key="zaipaiqian" onClick={() => {
			setModalFlag(true);
			setModalConfig({
				title: '申诉确认？',
				body: <>
					<Form.Item label={null} extra={<span style={{ color: 'red' }}>*必选</span>}>
						<Select placeholder="请选择是否同意申诉内容" onChange={value => {
							setAgree(value);
						}}>
							{
								[{ id: 1, label: '是' }, { id: 2, label: '否' }].map((item, idx) => (
									<Select.Option key={idx} value={item.id}>{item.label}</Select.Option>
								))
							}
						</Select>
					</Form.Item>
					<Form.Item label={null}>
						<Input.TextArea placeholder="请输入理由" rows={4} onChange={e => {
							setResult(e.target.value);
						}} />
					</Form.Item>
				</>,
				action: (obj = {}) => {
					if(!obj.agree) {
						message.error('请选择是否同意');
						return;
					}
					doProcess({
						id: record.id,
						status: ({ 1: 21, 2: 33 })[obj.agree],
						result: obj.result
					}, () => {
						message.success('操作成功');
						cancelModal();
					});
				}
			});
		}}>申诉审核</Button>,
	}
	return (
		<div>
			{buttonDom[domId] || null}
			<Modal {...{
				width: '65%',
				open: metchinFlag,
				title: <><WarningOutlined /><span>{modalConfig.modalTitle || '提示'}</span></>,
				footer: null,
				onCancel: cancelMetching,
				destroyOnClose: true
			}}>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<span>{modalConfig.title}</span>
					{
						modalConfig.body && <div style={{ marginTop: 10 }}>{modalConfig.body}</div> || null
					}
				</div>
				{/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
					<Button onClick={cancelModal}>取消</Button>
					<Button type="primary" style={{ marginLeft: 16 }} onClick={() => {
						modalConfig.action({ processor, processorExpired, result, fileLists, agree, delayExpired, editContent, editAddress });
					}}>确认</Button>
				</div> */}
			</Modal>
			<Modal {...{
				width: '65%',
				open: modalFlag,
				title: <><WarningOutlined /><span>{modalConfig.modalTitle || '提示'}</span></>,
				footer: null,
				onCancel: cancelModal,
				destroyOnClose: true
			}}>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<span>{modalConfig.title}</span>
					{
						modalConfig.body && <div style={{ marginTop: 10 }}>{modalConfig.body}</div> || null
					}
				</div>
				<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
					<Button onClick={cancelModal}>取消</Button>
					<Button type="primary" style={{ marginLeft: 16 }} onClick={() => {
						modalConfig.action({ processor, processorExpired, result,fileLists, agree, delayExpired, editContent, editAddress });
					}}>确认</Button>
				</div>
			</Modal>
		</div>
	);
};

export default CompModel;
