/**
 * Created by jimmy on 2019/9/29.
 */
import React, { useState, useEffect } from 'react';
import './index.less';
import ProblemBtnItem from "../problemBtnItem";

const CompModel = props => {
	const { status, record, workerList, categoryList, sevenCategory, callback } = props;
	const _style = { marginBottom: 10, marginRight: 10, 	fontSize: 12 };
	// console.log("categoryList:",categoryList)
	return ({
		11: <>
			<ProblemBtnItem {...{
				domId: '11-bianji', record: record, style: _style, workerList: workerList, baseCategoryList:categoryList,sevenCategory:sevenCategory,
				callback
			}} />
			<ProblemBtnItem {...{
				domId: '11-shouli', record: record, style: _style,workerList: workerList, baseCategoryList:categoryList,sevenCategory:sevenCategory,
				callback
			}} />
			<ProblemBtnItem {...{
				domId: '11-shoulilian', record: record, style: _style,workerList: workerList, baseCategoryList:categoryList,sevenCategory:sevenCategory,
				callback
			}} />
			<ProblemBtnItem {...{
				domId: '11-zuofie', record: record, style: _style,workerList: workerList, baseCategoryList:categoryList,sevenCategory:sevenCategory,
				callback
			}} />
		</>,
		// 21: <>
		// 	<ProblemBtnItem {...{
		// 		domId: '21-lian', record: record, style: _style,workerList: workerList, baseCategoryList:categoryList,sevenCategory:sevenCategory,
		// 		callback
		// 	}} />
		// 	<ProblemBtnItem {...{
		// 		domId: '21-tuihui', record: record, style: _style,workerList: workerList, baseCategoryList:categoryList,sevenCategory:sevenCategory,
		// 		callback
		// 	}} />
		// </>,
		31: <>
			<ProblemBtnItem {...{
				domId: '31-paiqian', record: record, style: _style,workerList: workerList, baseCategoryList:categoryList,sevenCategory:sevenCategory,
				callback
			}} />
			<ProblemBtnItem {...{
				domId: '31-tuidan', record: record, style: _style,workerList: workerList, baseCategoryList:categoryList,sevenCategory:sevenCategory,
				callback
			}} />
		</>,
		32: <>
			<ProblemBtnItem {...{
				domId: '32-paiqian', record: record, style: _style,workerList: workerList, baseCategoryList:categoryList,sevenCategory:sevenCategory,
				callback
			}} />
			<ProblemBtnItem {...{
				domId: '32-tuidan', record: record, style: _style,workerList: workerList, baseCategoryList:categoryList,sevenCategory:sevenCategory,
				callback
			}} />
		</>,
		33: <>
			<ProblemBtnItem {...{
				domId: '40-chuli', record: record, style: _style,workerList: workerList, baseCategoryList:categoryList,sevenCategory:sevenCategory,
				callback
			}} />
			{
				(window.localStorage.getItem("type") == 1) &&
					<ProblemBtnItem {...{
						domId: '33-shouhuipaiqian', record: record, style: _style, workerList: workerList, baseCategoryList: categoryList, sevenCategory: sevenCategory,
						callback
					}} />
			}
			{
				window.localStorage.getItem("type") == 1 &&
				<ProblemBtnItem {...{
					domId: '33-cuidan', record: record, style: _style,
					callback
				}} />
			}
		</>,
		40:<>
			<ProblemBtnItem {...{
				domId: '40-chuli', record: record, style: _style, workerList: workerList, baseCategoryList: categoryList, sevenCategory: sevenCategory,
				callback
			}} />
			{
				(window.localStorage.getItem("type") == 1) &&
				<div>
						<ProblemBtnItem {...{
							domId: '33-shouhuipaiqian', record: record, style: _style, workerList: workerList, baseCategoryList: categoryList, sevenCategory: sevenCategory,
							callback
						}} />
						<ProblemBtnItem {...{
							domId: '33-cuidan', record: record, style: _style,
							callback
						}} />
				</div>
			}
		</>,
		41: <>
		{
				(window.localStorage.getItem("type") == 1) &&
				<div>
						<ProblemBtnItem {...{
							domId: '41-jiean', record: record, style: _style, workerList: workerList, baseCategoryList: categoryList, sevenCategory: sevenCategory,
							callback
						}} />
						<ProblemBtnItem {...{
							domId: '41-tuihuichuli', record: record, style: _style, workerList: workerList, baseCategoryList: categoryList, sevenCategory: sevenCategory,
							callback
						}} />
				</div>
		}
			
		</>,
		43: <>
			<ProblemBtnItem {...{
				domId: '40-chuli', record: record, style: _style, workerList: workerList, baseCategoryList: categoryList, sevenCategory: sevenCategory,
				callback
			}} />
		</>,
		51: <>
			<ProblemBtnItem {...{
				domId: '51-tuidanshenhe', record: record, style: _style,workerList: workerList, baseCategoryList:categoryList,sevenCategory:sevenCategory,
				callback
			}} />
		</>,
		52: <>
			<ProblemBtnItem {...{
				domId: '52-yanqi', record: record, style: _style,workerList: workerList, baseCategoryList:categoryList,sevenCategory:sevenCategory,
				callback
			}} />
		</>,
		53: <>
			<ProblemBtnItem {...{
				domId: '53-shensu', record: record, style: _style,workerList: workerList, baseCategoryList:categoryList,sevenCategory:sevenCategory,
				callback
			}} />
		</>
	})[status] || null;
};

export default CompModel;
