/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { Button, Form, Select, Table, Modal, Input, DatePicker, InputNumber, message, Row, Col, Upload } from 'antd';
import { Line, OrganizationTreeGraph, Column } from '@ant-design/charts';
import { WarningOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import * as Request from '../../common/request';
import * as Utils from '../../common/utils';
import * as CONST from '../../common/const';
import Page from '../../components/page';
import FilterForm from '../../components/filterForm';
import UploadPictureList from '../../components/uploadPictureList';

import './index.less';

const PageModel = props => {
	const [form] = Form.useForm();

	const [ list, setList ] = useState([]);
	const [ page, setPage ] = useState(1);
	const [ pageSize, setPageSize ] = useState(10);
	const [ pageTotal, setPageTotal ] = useState(0);
	const getList = async (data = {}) => {
		data.page = page - 1;
		data.pageSize = pageSize;
		const result = await Request.get({
			url: '/admin/message/list',
			data
		});
		if(!result) return;

		result.data.list.map((item, idx) => {
			item.key = String(Math.random()) + idx;

			return item;
		});

		setList([...result.data.list]);
		setPageTotal(result.data.count);
	};
	useEffect(() => {
		getList();
	}, [ page ]);
	useEffect(() => {
		setPage(1);
	}, [ pageSize ]);

	const cancelHandle = () => {
		form.resetFields();
		setTempFormValues(null);
		setModalFlag(false);
	};

	const cancelHandlereply = () => {
		form.resetFields();
		setTempFormValues(null);
		setModalReplyFlag(false);
	};

	const [ modalFlag, setModalFlag ] = useState(false);
	const [ modalReplyFlag, setModalReplyFlag] = useState(false);
	const [ tempFormValues, setTempFormValues ] = useState(null);

	const submitHandleFlag = async (data, callback) => {
		const result = await Request.post({
			url: '/admin/message/reply',
			data
		});
		if (!result) return;
		message.success('操作成功');
		setTempFormValues(null);
		callback && typeof callback == 'function' && callback();
	};
	const submitHandle = async (data, callback) => {
		const result = await Request.post({
			url: '/admin/message/review',
			data
		});
		if(!result) return;
		message.success('操作成功');
		setTempFormValues(null);

		callback && typeof callback == 'function' && callback();
	};

	const deleteHandle = async (id, callback) => {
		const result = await Request.post({
			url: '/admin/message/delete',
			data: {
				id
			}
		});
		if(!result) return;
		message.success('操作成功');

		callback && typeof callback == 'function' && callback();
	};

	const [ userInfo, setUserInfo ] = useState({});

	return (
		<Page {...{
			title: '留言管理',
			breadcrumbs: [ { text: '首页' }, { text: '留言管理' } ],
			syncUserInfo: data => {
				setUserInfo(data);
			},
			...props
		}}>
			<section className="message-container">
				<FilterForm {...{
					fields: [
						{ type: 'select', label: '是否录取', name: 's-deployStatus', placeholder: '请选择', options: [{ id: 1, label: '待审核' }, { id: 2, label: '已录取' }, { id: 3, label: '未录取' }] },
						{ type: 'input', label: '留言内容', name: 's-content', placeholder: '请输入留言内容' },
					],
					filterHandle: data => {
						getList(data);
					},
					exportHandle: async data => {
						data.status = Utils.getSearchByKey('status');
						const result = await Request.get({
							url: '/admin/message/list/export',
							data
						});
						if(!result) return;
						window.open(result.data.path);
					},
				}} />

				<Table {...{
					style: { width: window.innerWidth - 168 - 20*2 },
					scroll: { x: 5 * 156 + 220 },
					columns: [
						{ title: '留言内容', dataIndex: 'content', key: 'content', width: 345, fixed: 'left', },
						{ title: '平台回复', dataIndex: 'reply', key: 'reply', width: 345, fixed: 'left', },
						{
							title: '是否录取', dataIndex: 'deployStatus', key: 'deployStatus',
							render: (text, record, index) => <span>{({1: '待审核', 2: '已录取', 3: '未录取'})[record.deployStatus]}</span>
						},
						{
							title: '发布时间', dataIndex: 'createdAt', key: 'createdAt',
							render: (text, record, index) => <span>{ record.createdAt && moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss') }</span>
						},
						{
							title: '发布者', dataIndex: 'author', key: 'author',
							render: (text, record, index) => {
								let str = record.authorInfo && record.authorInfo.nickname;
								if(record.authorInfo && record.authorInfo.department) {
									str += `（${record.authorInfo.department}）`;
								}
								return <span>{str}</span>
								// return <span>{record.authorInfo && record.authorInfo.nickname}</span>
							}
						},
						{
							title: '审核者', dataIndex: 'inspector', key: 'inspector',
							render: (text, record, index) => <span>{record.inspectorInfo && record.inspectorInfo.name}</span>
						},
						{ title: '备注信息', dataIndex: 'remark', key: 'remark', width: 180 },

						{
							title: '操作', dataIndex: 'edit', key: 'edit', width: 180, fixed: 'right',
							render: (text, record, index) => {
								const _style = { marginBottom: 10, marginRight: 10, 	fontSize: 12 };
								return <>
									<Button size="small" type="primary" style={_style} disabled={record.deployStatus != 1} onClick={() => {
										if(record.deployStatus != 1) return;

										form.resetFields();
										setTempFormValues(record);
										setModalFlag(true);
									}}>审核</Button>
									<Button size="small" type="primary" style={_style} onClick={() => {
										form.resetFields();
										setTempFormValues(record);
										setModalReplyFlag(true);
									}}>回复</Button>
									<Button size="small" type="primary" style={_style} disabled={record.deployStatus == 1} onClick={() => {
										Modal.confirm({
											title: `确认${({ 2: '下线', 3: '上线' })[record.deployStatus]}该留言`,
											icon: <WarningOutlined style={{ color: '#E45357' }} />,
											/*content: <Input placeholder="请输入备注信息" defaultValue={record.remark} allowClear style={{ marginTop: 10 }} onChange={e => {
												setTempFormValues({ remark: e.target.value })
											}} />,*/
											okText: '确认',
											cancelText: '取消',
											onOk: () => {
												submitHandle({
													id: record.id,
													deployStatus: ({2: 3, 3: 2})[record.deployStatus]
												}, () => {
													setTempFormValues(null);
													getList();
												});
											},
											onCancel: () => {
												// console.log('Cancel');
											},
										});
									}}>{({ 1: '上线', 2: '下线', 3: '上线' })[record.deployStatus]}</Button>
									{
										Utils.isSuperAdmin(userInfo?.mobile) && <Button danger type="text" size="small" style={_style} onClick={() => {
											Modal.confirm({
												title: '删除的数据将无法找回，请慎重操作！',
												icon: <WarningOutlined style={{ color: '#E45357' }} />,
												// content: 'Bla bla ...',
												okText: '确认删除',
												cancelText: '取消',
												onOk: () => {
													deleteHandle(record.id, () => {
														getList();
													})
												},
												onCancel: () => {
													// console.log('Cancel');
												},
											});
										}}>删除</Button>
									}
								</>
							}
						},
					],
					dataSource: list,
					pagination: {
						current: page,
						onChange: setPage,
						total: pageTotal,
						onShowSizeChange: (current, size) => {
							setPageSize(size);
						}
					}
				}} />
			</section>

			{
				!!modalFlag && <Modal {...{
					visible: modalFlag,
					width: 1020,
					footer: null,
					maskClosable: false,
					onCancel: cancelHandle
				}}>
					<Form {...{
						initialValues: tempFormValues,
						form,
						labelCol: { span: 6 },
						wrapperCol: { span: 16 },
						onFinish: values => {
							values.id = tempFormValues.id;
							submitHandle(values, () => {
								setModalFlag(false);
								getList();
							});
						}
					}}>
						<Form.Item label="留言内容" name="content">
							<Input disabled />
						</Form.Item>
						<Form.Item label="是否录取" name="deployStatus">
							<Select>
								{
									[
										{ status: 1, statusLabel: '待审核' },
										{ status: 2, statusLabel: '审核通过并已录取' },
										{ status: 3, statusLabel: '未录取' },
									].map((item, idx) => {
										return <Select.Option key={idx} value={item.status}>{item.statusLabel}</Select.Option>
									})
								}
							</Select>
						</Form.Item>
						<Form.Item label="备注信息" name="remark">
							<Input placeholder="请输入" allowClear />
						</Form.Item>

						<Form.Item label=" " colon={false}>
							<Button style={{ marginRight: 16 }} onClick={cancelHandle}>取消</Button>
							<Button style={{ marginRight: 16 }} type="primary" htmlType="submit">提交</Button>
						</Form.Item>
					</Form>
				</Modal>
			}

			{
				!!modalReplyFlag && <Modal {...{
					visible: modalReplyFlag,
					width: 1020,
					footer: null,
					maskClosable: false,
					onCancel: cancelHandlereply
				}}>
					<Form {...{
						initialValues: tempFormValues,
						form,
						labelCol: { span: 6 },
						wrapperCol: { span: 16 },
						onFinish: values => {
							values.id = tempFormValues.id;
							submitHandleFlag(values, () => {
								setModalReplyFlag(false);
								getList();
							});
						}
					}}>
						<Form.Item label="回复内容" name="reply">
							<Input />
						</Form.Item>
						<Form.Item label=" " colon={false}>
							<Button style={{ marginRight: 16 }} onClick={cancelHandlereply}>取消</Button>
							<Button style={{ marginRight: 16 }} type="primary" htmlType="submit">提交</Button>
						</Form.Item>
					</Form>
				</Modal>
			}
		</Page>
	);
};

export default PageModel;
