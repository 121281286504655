/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import { Button, Form, Select, Table, Modal, Image, Input, DatePicker, Tooltip, message, Radio, Row, Col, Divider, Steps, Breadcrumb, Descriptions, Badge, Timeline, Space } from 'antd';
import moment from 'moment';
import UploadPictureList from '../../components/uploadPictureList';
import * as Request from '../../common/request';
import * as CONST from '../../common/const';
// import NewFileViewer from 'react-file-viewer';
import Page from '../../components/page';

import './index.less';
import ProblemBtnItems from "../../components/problemBtnItems";

const PageModel = props => {
	const [form] = Form.useForm();
	const { id } = props.match.params;

	const [ type, setType] = useState('docx')
	const [ compareFlag, setCompareFlag ] = useState(false);
	const [ compareList, setCompareList ] = useState({ origin: [], result: [] });
	const [ workerList, setWorkerList ] = useState([]);
	const [ data, setData ] = useState({});
	const getData = async () => {
		const result = await Request.get({
			url: `/admin/problem/get/${id}`
		});
		if(!result) return;

		setData(result.data);
	};
	const [ categoryList, setCategoryList ] = useState([]);
	const getCategoryList = async () => {
		await getWorkerList();
		const result = await Request.get({
			url: '/admin/problem/category/list'
		});
		if(!result) return;

		if(result.data.list && result.data.list.length > 0) {
			setCategoryList(result.data.list);
		}
	};
	useEffect(() => {
		getCategoryList();
		getData();
	}, [123]);

	const getWorkerList = async () => {
		const result = await Request.get({
			url: '/admin/user/worker/list'
		});
		if(!result) return;

		if(result.data.list && result.data.list.length > 0) {
			setWorkerList(result.data.list);
		}
	};
	useEffect(() => {
	}, [ 123 ]);

	const getCategoryLabel = (list = []) => {
		let label = [];
		list.map((code, idx) => {
			const [ target ] = categoryList.filter(item => item.code == code);
			if(target && target.label) {
				if(idx == 0) {
					label.push(`<span style="font-weight: bold; font-size: 14px;">${target.label}</span>`);
				}else{
					label.push(target.label);
				}
			}
		})
		label = label.join('<br />');

		return label;
	};

	const [ sevenCategory, setSevenCategoryList ] = useState([]);
	const getSevenCategoryList = async () => {
		// const result = await Request.get({
		// 	url: '/admin/problem/category/list',
		// 	data: { type: 'seven' }
		// });
		// if(!result) return;
		//
		// if(result.data.list && result.data.list.length > 0) {
		// 	setSevenCategoryList(result.data.list);
		// }
		if(CONST.sevenCategoryList && CONST.sevenCategoryList.length > 0) {
			setSevenCategoryList(CONST.sevenCategoryList);
		}
	};
	useEffect(() => {
		getSevenCategoryList();
	}, [ 123 ]);

	const [ imageVisible, setImageVisible ] = useState(false);
	const [ visibleCurrentIndex, setVisibleCurrentIndex ] = useState(0);
	const [ visibleList, setVisibleList ] = useState([]);
	const [ visibleModal, setVisibleMode] = useState(false);
	const [ previewImage, setPreviewImage] = useState('');

	return (
		<Page {...{
			title: '详情',
			breadcrumbs: [ { text: '首页' }, { text: '问题管理' }, { text: data.number } ],
			...props
		}}>
			<Descriptions title="" bordered labelStyle={{ minWidth: 140 }}>
				<Descriptions.Item label="问题描述">{data && data.content}</Descriptions.Item>
				<Descriptions.Item label="问题类型"><span dangerouslySetInnerHTML={{ __html: data && data.category && getCategoryLabel(data.category) }} /></Descriptions.Item>
				<Descriptions.Item label='属于"七大类"'>{(() => {
					const [ target = {} ] = sevenCategory.filter(item => item.code == data.sevenCode);
					return <div style={{ width: '100%' }}>{target && target.label || '否'}</div>
				})()}</Descriptions.Item>
				<Descriptions.Item label="问题发生地址">{data && data.address}</Descriptions.Item>
				<Descriptions.Item label="当前状态">{data && data.status && CONST.problemStatusMap[data.status]}</Descriptions.Item>
				<Descriptions.Item label="处理部门">{data && data.processorInfo && data.processorInfo.department}</Descriptions.Item>
				<Descriptions.Item label="照片信息" >
					<div style={{ display: 'flex' }}>{ data && data.covers && data.covers.map((cover, idx) => cover && (
						/*<img key={idx} src={CONST.imgPrefix + cover} style={{ width: 56, height: 56, marginRight: 8, cursor: 'pointer' }} onClick={() => {
							window.open(CONST.imgPrefix + cover);
						}} />*/
						<Image preview={{ visible: false }} key={idx} src={CONST.imgPrefix + cover} height={500} width={500} onClick={() => {
							setImageVisible(true);
							setVisibleCurrentIndex(idx);
							setVisibleList(data.covers);
						}} />
					) || null) }</div>
				</Descriptions.Item>
				<Descriptions.Item label="上报人">{data && data.reporterInfo && data.reporterInfo.nickname}</Descriptions.Item>
				<Descriptions.Item label="上报时间">{data && data.createdAt && moment(data.createdAt).format('YYYY-MM-DD HH:mm')}</Descriptions.Item>
				<Descriptions.Item label="市民满意度">{ data && data.review && CONST.reviewMap[data.review] }</Descriptions.Item>
			</Descriptions>

			<Divider orientation="left"><span style={{ fontSize: 14, fontWeight: 'inherit' }}>问题处理流程</span></Divider>
			<div style={{ position: 'relative', marginLeft: 20 }}>
				<em style={{ position: 'absolute', top: 10, left: 222, width: 1, bottom: 10, backgroundColor: '#CCCCCC', transform: 'translateX(50%)' }} />
				{
					data.result && data.result.length > 0 && data.result.map((item, idx) => {
						// item.images = [ ...data.covers, ...data.covers, ...data.covers ];
						const isLast = idx == data.result.length - 1;
						const dotBorderColor = idx == 0 ? 'green' : ( isLast ? 'red' : 'grey' );
						return (
							<div style={{ display: 'flex', marginBottom: isLast ? 0 : 28, marginLeft: 58, position: 'relative' }} key={idx}>
								<label style={{ width: 160, marginRight: 30, color: '#999' }}>{item.time}</label>
								<em style={{ position: 'absolute', top: 6, left: 160, width: 10, height: 10, borderRadius: '50%', border: `solid 2px ${dotBorderColor}`, backgroundColor: '#FFFFFF' }} />
								<div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
									<div style={{ display: 'flex' }}>
										<span style={{ color: '#414141', marginRight: 16 }}>{item.content}</span>
										{
											data.status == item.status && isLast && <ProblemBtnItems {...{
												status: data.status,
												record: data,
												workerList: workerList,
												categoryList:categoryList,
												sevenCategory:sevenCategory,
												callback: () => {
													getData();
													// setTimeout(() => {
													// 	props.history.push(`/problem?status=40`);
													// }, 800);
												}
											}} />
										}
										{
											item.status == 11 && data.status == 31 &&  <ProblemBtnItems {...{
												status: data.status,
												record: data,
												workerList: workerList,
												categoryList: categoryList,
												sevenCategory: sevenCategory,
												callback: () => {
													getData();
													// setTimeout(() => {
													// 	props.history.push(`/problem?status=40`);
													// }, 800);
												}
											}} />
										}
									</div>
									<div style={{ display: 'flex', alignItems: 'flex-end' }}>
										{
											item && item.images && item.images.length > 0 && item.images.map((imgItem, imgIdx) => {
												console.log('====',item.images);
												if(!imgItem) return null;
												return <div style={{ position: 'relative', marginRight: item.images.length - 1 == imgIdx ? 0 : 10 }} key={imgIdx}>
													{/*<Image src={CONST.imgPrefix + imgItem} height={56} width={56} />*/}
													
													{/* <Image preview={{ visible: false }} src={CONST.imgPrefix + imgItem} height={56} width={56} onClick={() => {
														setImageVisible(true);
														setVisibleCurrentIndex(imgIdx);
														setVisibleList(item.images);
													}} /> */}

													<UploadPictureList {...{
														pictureLength: 1,
														maxCount:1,
														action: CONST.uploadPrefix + '/admin/dynamic/upload',
														fileList: [imgItem],
														changeHandle: async (fileList) => {
															console.log('data=', data);
															console.log('imgItem====', imgItem);

															//删除附件照片
																const result = await Request.post({
																	url: `/admin/problem/delete`,
																	data:{
																		id:data.id,
																		url: imgItem
																	}
																});
																console.log(result);
																// if (!result) return;
																// setData(result.data);
															// }; 
														},
														onPreviewUrl:(url)=>{
															console.log('url==', url);
															setVisibleMode(true);
															setPreviewImage(url)
														}
													}} />

												</div>
												// return <div style={{ position: 'relative', marginRight: item.images.length - 1 == imgIdx ? 0 : 10 }} key={imgIdx} onClick={() => {
												// 	window.open(CONST.imgPrefix + imgItem);
												// }}>
												// 	<span className="icon-fangdajing iconfont" style={{ position: 'absolute', right: 8, top: 4, zIndex: 2 }} />
												// 	<img src={CONST.imgPrefix + imgItem} style={{ width: 144 }} />
												// </div>
											})
										}
										{
											item && item.images && item.images.length > 0 && <div style={{ cursor: 'pointer', textDecoration: 'underline', color: '#E45357', marginLeft: 20 }} onClick={() => {
												setCompareFlag(true);
												// setCompareList([data.covers || [], item.images || []])
												setCompareList({ origin: data.covers || [], result: item.images || []});
											}}>照片比对</div>
										}
									</div>
								</div>
							</div>
						)
					})
				}
			</div>

			<Modal {...{
				title: '处理前后照片对比',
				open: compareFlag,
				width: '96%',
				footer: null,
				onCancel: () => {
					setCompareFlag(false);
				},
				className: 'detail-picture-compare-modal-wrapper'
			}}>
				<div className="detail-picture-block">
					<div className="detail-picture-desc">处理前</div>
					{
						compareList.origin && compareList.origin.length > 0 && compareList.origin.map((cover, idx) => cover && <img style={{ cursor: 'pointer' }} key={idx} src={CONST.imgPrefix + cover} onClick={() => {
							// window.open(CONST.imgPrefix + cover);
							setImageVisible(true);
							setVisibleCurrentIndex(idx);
							setVisibleList([...compareList.origin]);
						}} /> || null)
					}
				</div>
				<div className="detail-picture-block">
					<div className="detail-picture-desc">处理结果</div>
					{/* {
						compareList.result && compareList.result.length > 0 && compareList.result.map((cover, idx) => cover && <img style={{ cursor: 'pointer' }} key={idx} src={CONST.imgPrefix + cover} onClick={() => {
							setImageVisible(true);
							setVisibleCurrentIndex(idx);
							setVisibleList([...compareList.result]);
						}} /> || null)
					} */}
					{
						compareList.result && compareList.result.length > 0 && compareList.result.map((cover, idx) => cover && <UploadPictureList {...{
							pictureLength: 1,
							maxCount: 1,
							// action: CONST.uploadPrefix + '/admin/dynamic/upload',
							fileList: [cover],
							changeHandle: fileList => {
								console.log('===fileList===', fileList);
								// setFileList(fileList);
							},
							onPreviewUrl: (url) => {
								console.log('url==', url);
								setVisibleMode(true);
								setPreviewImage(url)
							}
						}} /> || null)
					}
				</div>
			</Modal>

			<Modal open={visibleModal} footer={null} onCancel={()=>{
				setVisibleMode(false)
			}}>
				<img alt="example" style={{ width: '100%' }} src={CONST.imgPrefix + previewImage} />
				{/* <NewFileViewer
					fileType={type}
					filePath={'https://zjj-adjust.oss-cn-shanghai.aliyuncs.com/《中华人民共和国行政复议法》.docx'}
					errorComponent={(val)=>{
						console.log('err=', val)
					}}
					onError={err => console.log(err)}
				/> */}
			</Modal>

			<div
				style={{
					display: 'none',
				}}
			>
				<Image.PreviewGroup
					preview={{
						visible: imageVisible,
						onVisibleChange: (vis) => setImageVisible(vis),
						current: visibleCurrentIndex
					}}
				>
					{
						visibleList && visibleList.length > 0 && visibleList.map((cover, idx) => {
							return <Image src={CONST.imgPrefix + cover} />
						})
					}
				</Image.PreviewGroup>
			</div>
		</Page>
	);
};

export default PageModel;
